import {
  BackstageTheme,
  createTheme,
  genPageTheme,
  lightTheme,
  darkTheme,
  shapes,
} from '@backstage/theme';
import { BackstageOverrides } from '@backstage/core-components';

export const createCustomThemeOverrides = (
  theme: BackstageTheme,
): BackstageOverrides => {
  return {
    BackstageHeader: {
      header: {
        width: 'auto',
        boxShadow: 'none',
        padding: "20px 30px 20px 20px"
      },
    },
    BackstageSidebarItem: {
      root: {
        color: '#999',
        borderLeft: 'none !important',
        '&:hover': {
          background: 'none !important',
          color: '#fff !important',
          borderLeft: 'none !important',
        },
      },
      selected: {
        background: 'none',
        borderLeft: 'none !important',
        marginLeft: '0 !important'
      },
      iconContainer:{
        marginLeft: '0 !important'
      },
      label: {
        marginTop: '-3px',
      },
      closed: {
        width: '65px !important'
      }
    },
    BackstageContent: {
      root: {
        background: theme.palette.background.default
      }
    }
  };
};

const dark = createTheme({
  palette: {
    ...darkTheme.palette,
    navigation: {
      background: '#222',
      indicator: '#ffffff',
      color: '#999',
      selectedColor: '#ffffff',
    },
  },
  defaultPageTheme: 'home',
});

const light = createTheme({
  palette: {
    ...lightTheme.palette,
    primary: {
      main: '#052e4c',
    },
    secondary: {
      main: '#5a7d96',
    },
    error: {
      main: '#8c4351',
    },
    warning: {
      main: '#8f5e15',
    },
    info: {
      main: '#34548a',
    },
    success: {
      main: '#485e30',
    },
    background: {
      default: '#fff',
      paper: '#fff',
    },
    banner: {
      info: '#34548a',
      error: '#8c4351',
      text: '#343b58',
      link: '#565a6e',
    },
    errorBackground: '#8c4351',
    warningBackground: '#8f5e15',
    infoBackground: '#343b58',
    navigation: {
      background: '#052e4c',
      indicator: '#ffffff',
      color: '#999',
      selectedColor: '#ffffff',
    },
  },
  defaultPageTheme: 'home',
  pageTheme: {
    home: genPageTheme({ colors: ['#5a7d96', '#052e4c'], shape: shapes.wave }),
    documentation: genPageTheme({
      colors: ['#8c4351', '#343b58'],
      shape: shapes.wave2,
    }),
    tool: genPageTheme({ colors: ['#8c4351', '#343b58'], shape: shapes.round }),
    observenow: genPageTheme({
      colors: ['#dfdfdf', '#999'],
      shape: shapes.wave,
    }),
    deploynow: genPageTheme({
      colors: ['#999', '#dfdfdf'],
      shape: shapes.wave2,
    }),
    library: genPageTheme({
      colors: ['#8c4351', '#343b58'],
      shape: shapes.wave,
    }),
    other: genPageTheme({ colors: ['#8c4351', '#343b58'], shape: shapes.wave }),
    app: genPageTheme({ colors: ['#8c4351', '#343b58'], shape: shapes.wave }),
    apis: genPageTheme({ colors: ['#8c4351', '#343b58'], shape: shapes.wave }),
  },
});

export const LightTheme: BackstageTheme = {
  ...light,
  overrides: {
    // These are the overrides that Backstage applies to `material-ui` components
    ...lightTheme.overrides,
    // These are your custom overrides, either to `material-ui` or Backstage components.
    ...createCustomThemeOverrides(lightTheme),
  },
};

export const DarkTheme: BackstageTheme = {
  ...dark,
  overrides: {
    // These are the overrides that Backstage applies to `material-ui` components
    ...darkTheme.overrides,
    // These are your custom overrides, either to `material-ui` or Backstage components.
    ...createCustomThemeOverrides(darkTheme),
  },
};
