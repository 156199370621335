import {
    HomePageToolkit,
    HomePageStarredEntities,
  } from '@backstage/plugin-home';
  
  import { Content, Page } from '@backstage/core-components';
  import { HomePageSearchBar } from '@backstage/plugin-search';
  import { Grid, makeStyles } from '@material-ui/core';
  import React from 'react';
  import CategoryIcon from '@material-ui/icons/Category';
  
  export default {
    title: 'Plugins/Home/Templates',
    decorators: [],
  };
  
  const useStyles = makeStyles(theme => ({
    searchBar: {
      display: 'flex',
      maxWidth: '60vw',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[1],
      padding: '8px 0',
      borderRadius: '50px',
      margin: 'auto',
    },
  }));
  
  export const HomePage = () => {
    const classes = useStyles();
    return (
  
      <Page themeId="home">
        <Content>
          <Grid container justifyContent="center" spacing={6}>
            <Grid container item xs={12} alignItems="center" direction="row">
              <HomePageSearchBar
                classes={{ root: classes.searchBar }}
                placeholder="Search"
              />
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} md={6}>
                <HomePageStarredEntities />
              </Grid>
              <Grid item xs={12} md={6}>
                <HomePageToolkit
                  tools={
                    Array(1).fill({
                      url: 'catalog',
                      label: 'Go to catalog',
                      icon: <CategoryIcon />,
                  })
                }
                />
              </Grid>
            </Grid>
          </Grid>
        </Content>
      </Page>
    );
  };